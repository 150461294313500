import { CircularProgress, Divider, Stack, Typography } from '@mui/material';
import Button from '~/components/common/Button';
import { useLocation } from 'react-router-dom';
import {
  ASAP_OPTION,
  DeliveryHourType,
  DisplayASAPOptions,
  IStore,
  OrderLimitType,
  StorePlatform,
  TimePickerOptions,
} from '~/models/stores';
import themes, { styled } from '~/themes';
import { fromQueryString } from '~/utils/queryString';
import { Form, Formik } from 'formik';
import { DeliveryHoursSchema } from '~/utils/schema/stores';
import GroupRadioButton from '~/components/common/GroupRadioButton';
import PreivewIcon from '~/assets/images/icons/preview.svg';
import CheckBox from '~/components/common/CheckBox';
import { alertParams, showAlert } from '~/components/common/Alert';
import { useUpdateConnectionMutation } from '~/services/api/userManagement';
import { cloneDeep, isEqual } from 'lodash';
import { useState } from 'react';
import SpecialHours from './components/SpecialHours';
import TimeSlots from './components/TimeSlots';
import Preview from './components/Preview';

const StyledLoading = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9,
  background: 'rgba(256, 256, 256, 0.7)',
}));

type Props = {
  data: IStore;
};

const convertedDeliveryHours = (deliveryHours) => {
  const weekDays = Object.keys(deliveryHours);
  return weekDays.map((wd) => {
    const hours = deliveryHours[wd];
    const { time_slot, last_order_time } = hours;

    return {
      weekdayValue: wd,
      weekDayLabel: wd.charAt(0).toUpperCase() + wd.slice(1),
      ...hours,
      last_order_time: last_order_time || '23:59',
      totalTimeSlot: time_slot?.length,
    };
  });
};

const convertedSpecialHours = (specialHours) =>
  specialHours?.map((hours) => ({
    ...hours,
    last_order_time: hours.last_order_time || '23:59',
  }));

export const DeliveryHours = ({ data }: Props) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const platform = search.platform as StorePlatform;
  const [openPreviewModal, setOpenPreviewModal] = useState(false);

  const { mutate: updateConnectionMutation, isLoading } =
    useUpdateConnectionMutation({
      onSuccess: () => {},
    });

  const isEqualDeliveryHoursData = (oldData, newData) => {
    const oldDeliveryHours = cloneDeep(oldData);
    const newDeliveryHours = cloneDeep(newData);

    return isEqual(
      {
        delivery_window: oldDeliveryHours.delivery_window,
        next_available_time: oldDeliveryHours.next_available_time,
      },
      {
        delivery_window: newDeliveryHours.delivery_window,
        next_available_time: newDeliveryHours.next_available_time,
      },
    );
  };

  const resetDeliveryHoursLimit = (deliveryHours) => {
    const weekDays = Object.keys(deliveryHours);
    weekDays.forEach((wd) => {
      deliveryHours[wd].limit = null;
      deliveryHours[wd]?.time_slot?.forEach((ts) => {
        ts.limit = null;
      });
    });
    return deliveryHours;
  };

  const onSubmit = (values) => {
    const isShowWarningMsg =
      values.enable_delivery &&
      values.limit_all_slot &&
      !isEqualDeliveryHoursData(data.delivery, values);

    if (values.limit_type === OrderLimitType.LimitAllSlot) {
      values.delivery_hours = resetDeliveryHoursLimit(values.delivery_hours);
    } else {
      values.limit_all_slot = null;
    }

    const params = {
      id: data.id,
      enable_delivery: values.enable_delivery,
      delivery: values,
      ...(values?.limit_type && {
        limit_type: values.limit_type,
      }),
      limit_all_slot: values.limit_all_slot
        ? parseInt(values.limit_all_slot, 10)
        : null,
      delivery_hour_type: values.delivery_hour_type,
    };

    if (isShowWarningMsg) {
      showAlert({
        ...alertParams.warning,
        description:
          'Your changes may affect your order limit settings.\nPlease review and adjust them.',
        okText: 'Continue',
        cancelText: 'Cancel',
        onOk: () => updateConnectionMutation(params),
      });
    } else {
      updateConnectionMutation(params);
    }
  };

  if (platform === StorePlatform.Breadstack) {
    return (
      <Stack spacing={2}>
        <Typography
          sx={{
            borderRadius: '5px',
            background: themes.bg.lightPurple,
            padding: 1,
          }}
        >
          Allow your customers to schedule specific delivery time windows for
          their packages, based on your store delivery hours.
        </Typography>

        <Button
          noRounder
          buttonType='default'
          sx={{ width: 'fit-content' }}
          onClick={() => window.open(data.breadstack_url, '_blank')}
        >
          Manage in Breadstack
        </Button>
      </Stack>
    );
  }

  return (
    <Stack spacing={2} pb={1}>
      <Formik
        initialValues={{
          delivery_window: 15,
          next_available_time: 30,
          ...data.delivery,
          enable_delivery: data.enable_delivery || false,
          limit_all_slot: data?.limit_all_slot || null,
          delivery_hour_type:
            data?.delivery?.delivery_hour_type || DeliveryHourType.Different,
          delivery_hours: data.delivery?.delivery_hours,
          special_hours: data.delivery?.special_hours || [],
          limit_type: data?.limit_type || OrderLimitType.LimitAllSlot,
          timezone: data?.timezone,
          asap_option: data.delivery.asap_option,
        }}
        validationSchema={DeliveryHoursSchema}
        enableReinitialize
        onSubmit={onSubmit}
      >
        {({ values, setFieldValue, isValid, dirty }) => (
          <Form>
            {isLoading && (
              <StyledLoading>
                <CircularProgress size={30} />
              </StyledLoading>
            )}
            <Typography variant='h5' color={themes.color.black}>
              Time picker
            </Typography>
            <GroupRadioButton
              name='enable_delivery'
              sx={{
                'ml': -0.5,
                'mt': 1,
                'gap': '5px 0px !important',
                '& > label': {
                  border: 'none',
                  padding: 0.5,
                  fontWeight: 'normal',
                },
              }}
              value={values.enable_delivery}
              onChange={(e) => {
                const value = JSON.parse(e.target.value);
                setFieldValue('enable_delivery', value);

                const new_special_hours = [];
                values?.special_hours?.forEach((special_hour) => {
                  const { from_time, to_time, last_order_time } = special_hour;

                  if (value && (!from_time || !to_time)) {
                    // new_special_hours.push({
                    //   ...special_hour,
                    //   available: false,
                    // });
                    return;
                  }

                  if (!value && !last_order_time) {
                    new_special_hours.push({
                      ...special_hour,
                      last_order_time: to_time || '23:59',
                    });
                    return;
                  }

                  new_special_hours.push(special_hour);
                });

                setFieldValue('special_hours', new_special_hours);
              }}
              options={TimePickerOptions.map((item) => ({
                ...item,
              }))}
            />
            {values.enable_delivery && (
              <Button
                noRounder
                buttonType='default'
                sx={{
                  mt: 1,
                  gap: '4px',
                }}
                onClick={() => setOpenPreviewModal(true)}
              >
                <img src={PreivewIcon} alt='preview' /> Preview Time Picker
              </Button>
            )}

            <Divider sx={{ my: 2 }} />
            <TimeSlots
              data={convertedDeliveryHours(values?.delivery_hours)}
              rawData={values?.delivery_hours}
              store={values}
              onChange={(val) => {
                setFieldValue('delivery_hours', val);
              }}
              enableDelivery={values.enable_delivery}
            />

            <Divider sx={{ my: 2 }} />
            <SpecialHours
              enableDelivery={values.enable_delivery}
              data={convertedSpecialHours(values?.special_hours)}
              store={values}
              rawData={values?.special_hours}
              onChange={(val) => {
                setFieldValue('special_hours', val);
              }}
            />

            <Divider sx={{ my: 2 }} />
            <Stack flexDirection='column' gap={1.8}>
              <Typography variant='h5' color={themes.color.black}>
                Settings
              </Typography>
              <Stack>
                <CheckBox
                  disabled={!values.enable_delivery}
                  checked={
                    !values.enable_delivery ? false : values.asap_option !== ''
                  }
                  onChange={(e) => {
                    if (e.target.checked) {
                      setFieldValue('asap_option', ASAP_OPTION.FIRST);
                    } else {
                      setFieldValue('asap_option', ASAP_OPTION.NONE);
                    }
                  }}
                  defaultCheckedIcon
                  label='Display ASAP as an option'
                />
                {values.asap_option !== ASAP_OPTION.NONE && (
                  <GroupRadioButton
                    sx={{
                      'pl': 1.5,
                      'gap': '5px 0px !important',
                      '& > label': {
                        border: 'none',
                        padding: 0.5,
                        fontWeight: 'normal',
                      },
                    }}
                    name='asap_option'
                    value={!values.enable_delivery ? '' : values.asap_option}
                    onChange={(e) => {
                      setFieldValue('asap_option', e.target.value);
                    }}
                    options={DisplayASAPOptions?.map((item) => ({
                      ...item,
                      disabled: !values.enable_delivery,
                    }))}
                  />
                )}
              </Stack>
            </Stack>

            <Button
              disabled={!isValid || !dirty}
              className='save-change-btn'
              fullWidth
              loading={isLoading}
              type='submit'
            >
              Save Changes
            </Button>
            {openPreviewModal && (
              <Preview
                open={openPreviewModal}
                onClose={() => {
                  setOpenPreviewModal(false);
                }}
                data={{ delivery: values, id: data.id }}
                timezone={data?.timezone}
              />
            )}
          </Form>
        )}
      </Formik>
    </Stack>
  );
};
