import React, { useMemo } from 'react';
import themes, { styled } from '~/themes';
import { Box, Container, Divider, Skeleton, Typography } from '@mui/material';
import NoImage from '~/assets/images/icons/no-avatar.svg';
import TrackPhone from '~/assets/images/icons/track-phone.svg';
import NotFoundImg from '~/assets/images/background/setup-your-store-background.png';
import { useGetTrackQuery } from '~/services/api/track';
import { useParams } from 'react-router-dom';
import { isNullOrUndefined } from '~/utils/common';
import { TrackStatus } from '~/models/track';
import { Stack } from '@mui/system';
import { IPlanTier } from '~/models/plan';
import { formatTaskDateTime } from '~/utils/formatter';
import LogoWhite from '~/assets/images/logo/canfleet-white.svg';
import TrackProgress from './TrackProgress';
import TrackRate from './TrackRate';
import { TrackVerticalProgress } from './TrackVerticalProgress';

export const StyledTextDesc = styled(Typography)(({ theme }) => ({
  color: theme.color.violet900,
  fontSize: 16,
  fontWeight: 500,
}));

export const StyledDriverName = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  textAlign: 'center',
  color: theme.color.gray900,
  fontSize: 16,
  background: theme.bg.gray300,
  padding: theme.spacing(0.6, 1.2),
  borderRadius: theme.spacing(0.5),
}));

export const StyledDriverAvatar = styled('img')(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  borderRadius: theme.spacing(2.5),
}));

export const StyledDriverInfo = styled(Box)(({ theme }) => ({
  'textAlign': 'right',
  '& > div': {
    '&:first-child': {
      color: theme.color.violet900,
      fontWeight: 'bold',
      fontSize: 20,
    },
    '&:last-child': {
      color: theme.color.gray900,
      fontSize: 16,
    },
  },
}));

export const StyledTrackPhoneButton = styled('a')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: theme.spacing(3.5),
}));

export const StyledEstTimeTitle = styled(Typography)(({ theme }) => ({
  color: theme.color.gray900,
  fontSize: 16,
  marginTop: theme.spacing(2),
}));

interface ITrack {}

export const Track: React.FC<ITrack> = () => {
  const { code } = useParams();
  const calendarFormats = {
    sameDay: 'hh:mm a [| today]',
    nextDay: 'hh:mm a [| tomorrow]',
    lastDay: 'hh:mm a [| yesterday]',
    nextWeek: 'hh:mm a | MM/DD/YYYY',
    lastWeek: 'hh:mm a | MM/DD/YYYY',
    sameElse: 'hh:mm a | MM/DD/YYYY',
  };
  const { data, isLoading: isFetching } = useGetTrackQuery({
    params: { code },
  });
  const isRating =
    data?.message === TrackStatus.Delivered &&
    data.org_tier === IPlanTier.Advance;

  const isDelivered =
    data?.message === TrackStatus.Delivered &&
    [IPlanTier.Standard, IPlanTier.Standard].includes(data.org_tier);
  const isDelivering =
    data?.message === TrackStatus.OrderIsOutForDelivery ||
    data?.message === TrackStatus.OrderIsArrivingSoon;

  const orderMessage = useMemo(() => {
    if (isDelivered) return 'Order is Delivered';
    return data?.message || '';
  }, [data?.message, isDelivered]);

  const deliveryTime = useMemo(() => {
    if (isDelivered) {
      return data.delivery_completed_time
        ? `Arrived at ${formatTaskDateTime(
            data.delivery_completed_time,
            calendarFormats,
          )}`
        : '';
    }

    if (!data?.expected_delivery_after) {
      return 'ASAP';
    }

    if (data?.expected_delivery_after && !data?.expected_delivery_before) {
      return `Arriving after ${formatTaskDateTime(
        data?.expected_delivery_after,
        calendarFormats,
      )}`;
    }

    return `Arriving by ${formatTaskDateTime(
      data?.expected_delivery_before,
      calendarFormats,
    )}`;
  }, [data, isDelivered]);

  const formattedEstTime = (minutes: number): string => {
    if (minutes < 60) {
      return `${minutes} mins`;
    }
    if (minutes < 1440) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours}h ${remainingMinutes}m`;
    }
    const days = Math.floor(minutes / 1440);
    const remainingHours = Math.floor((minutes % 1440) / 60);
    const remainingMinutes = minutes % 60;
    return `${days}d ${remainingHours}h ${remainingMinutes}m`;
  };

  const renderContent = () => {
    if (!data?.order_number && !isFetching) {
      return (
        <Stack
          sx={{
            padding: themes.spacing(3, 1.5),
            justifyContent: 'center',
            alignItems: 'center',
            mt: '25%',
          }}
          spacing={2}
        >
          <img src={NotFoundImg} alt='404.png' width={350} height={230} />
          <Typography variant='h1' fontSize={40}>
            404
          </Typography>
          <Typography variant='body1' color={themes.color.gray900}>
            Something went wrong
          </Typography>
        </Stack>
      );
    }

    if (isFetching) {
      return (
        <Stack spacing={1.5} py={3} px={1.5}>
          <Skeleton height={40} width='50%' />
          <Skeleton width='90%' />
          <Skeleton width='30%' />
          <Stack spacing={1}>
            <Skeleton width='20%' />
            <Skeleton width='22%' />
            <Skeleton width='30%' />
            <Skeleton width='15%' />
          </Stack>
          <Divider />
          <Skeleton width='25%' />
          <Skeleton width='20%' />
          <Skeleton width='20%' />
          <Skeleton width='20%' />
          <Skeleton width='20%' />
        </Stack>
      );
    }
    if (isRating) {
      return <TrackRate data={data} />;
    }

    return (
      <Stack py={3} px={1.5}>
        <Typography variant='h1' fontSize={26} mb={2}>
          {orderMessage}
        </Typography>
        <TrackProgress progress={(data?.progress_bar || 0) * 100} />
        {Boolean(
          !isNullOrUndefined(data?.estimated_delivery_time) &&
            isDelivering &&
            data?.estimated_delivery_time >= 0,
        ) && (
          <StyledEstTimeTitle>{`Estimated delivery time: ${formattedEstTime(
            data?.estimated_delivery_time || 0,
          )}`}</StyledEstTimeTitle>
        )}
        {Boolean(
          data?.driver_name &&
            data?.message &&
            !isDelivered &&
            data?.message !== TrackStatus.OrderIsConfirmed,
        ) && (
          <Stack
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              mt: themes.spacing(3),
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <StyledDriverAvatar
                src={
                  data?.driver_avatar && data?.driver_avatar_type
                    ? `data:${data.driver_avatar_type};base64,${data.driver_avatar}`
                    : NoImage
                }
                alt='avatar'
              />
              {data?.phone && (
                <StyledTrackPhoneButton href={`tel:${data?.phone}`}>
                  <img src={TrackPhone} alt='phone' />
                </StyledTrackPhoneButton>
              )}
            </Box>
            <StyledDriverInfo>
              <div>{data?.plate_number || ''}</div>
              <div>{data?.vehicle_body || ''}</div>
            </StyledDriverInfo>
          </Stack>
        )}
        {Boolean(data?.driver_name) && !isDelivered && (
          <StyledDriverName>{`${data?.driver_name} is taking care of your package`}</StyledDriverName>
        )}
        <StyledTextDesc
          sx={{
            mb: 2,
            mt: 3,
          }}
        >
          {deliveryTime}
        </StyledTextDesc>
        <TrackVerticalProgress value={data.progress_bar} />
        <Divider sx={{ my: 2.5 }} />
        <StyledTextDesc sx={{ mb: 2 }}>
          {`Order number: ${data?.order_number}`}
        </StyledTextDesc>
        {data?.pickup && (
          <Stack spacing={1} fontSize={16} color={themes.color.violet900}>
            <StyledTextDesc>Pickup Address:</StyledTextDesc>
            <span>{data?.pickup?.name || ''}</span>
            <span>{data?.pickup?.address_1 || ''}</span>
            {data?.pickup?.address_2 && <span>{data?.pickup?.address_2}</span>}
            <span>{`${data?.pickup?.city}, ${data?.pickup?.state} ${data?.pickup?.postcode}`}</span>
          </Stack>
        )}

        <Stack spacing={1} fontSize={16} mt={3} color={themes.color.violet900}>
          <StyledTextDesc>Delivery Address:</StyledTextDesc>
          <span>{data?.delivery_name || ''}</span>
          <span>{data?.address_1 || ''}</span>
          {data?.address_2 && <span>{data?.address_2}</span>}
          <span>{data?.address_body || ''}</span>
        </Stack>
      </Stack>
    );
  };

  return (
    <Container
      sx={{
        p: '0px !important',
        maxWidth: '600px !important',
        [themes.breakpoints.down('lg')]: {
          maxWidth: '100% !important',
        },
      }}
    >
      <Stack
        alignItems='center'
        direction='row'
        sx={{
          background: '#ED6808',
          height: 50,
          pl: 1.5,
        }}
      >
        <img width={30} height={15} src={LogoWhite} alt='logo' />
        <Typography
          fontFamily='Poppins'
          fontSize={18}
          fontWeight='bold'
          color={themes.color.white}
        >
          CanFleet
        </Typography>
      </Stack>
      {renderContent()}
    </Container>
  );
};
