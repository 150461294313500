/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import themes, { styled } from '~/themes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { fromQueryString, omitFromQueryString } from '~/utils/queryString';
import { DrawerTabs } from '~/components/common/Tabs';
import {
  STORES_DRAWER_TABS,
  STORES_DRAWER_TAB_VALUE,
} from '~/constants/stores';
import { useDeleteConnectionMutation } from '~/services/api/userManagement';
import { alertParams, showAlert } from '~/components/common/Alert';
import { IStore, IntegrationType } from '~/models/stores';
import Tutorial from '~/components/common/Tutorial';
import { TutorialData } from '~/constants/tutorial';
import { useAuth } from '~/providers/AuthProvider';
import { LinkButton } from '~/components/common/Button';
import { StyledCloseButton } from '~/themes/style';
import { useKeyDow } from '~/hooks/useKeyDown';
import { General } from './components/General';
import { Teams } from './components/Teams';
import { DeliveryHours } from './components/NewDeliveryHours';
import { ZoneAndFree } from './components/ZoneAndFree';
import { TaskSettings } from './components/TaskSetting';
import { OrderLimit } from './components/OrderLimit';

const StyledIntegrationListContainer = styled(Stack)(() => ({
  'position': 'relative',
  'height': '100%',
  'flexDirection': 'column',
  'boxSizing': 'border-box',
  '.save-change-btn': {
    'position': 'absolute',
    'bottom': 0,
    'left': 0,
    'borderRadius': 0,
    'minHeight': 50,
    '&.loading': {
      opacity: 1,
      background: '#FED1C7 !important',
    },
  },
}));

const StyledLoading = styled('div')(() => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 9,
  background: 'rgba(256, 256, 256, 0.7)',
}));

type StoreDetailProps = {
  storeData: IStore;
  isFetching?: boolean;
};

export const StoreDetail: React.FC<StoreDetailProps> = ({
  storeData,
  isFetching,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const search = fromQueryString(location.search);

  const id = search.id as string;
  const [tab, setTab] = useState(STORES_DRAWER_TAB_VALUE.General);
  const { account, getTooltipOpen, updateUserTooltip } = useAuth();

  const tabs = useMemo(() => {
    if (storeData.integration_type === IntegrationType.Shopify) {
      return STORES_DRAWER_TABS.filter(
        (t) => t.value !== STORES_DRAWER_TAB_VALUE.TaskSettings,
      );
    }

    return STORES_DRAWER_TABS;
  }, [search.type, storeData]);

  useEffect(() => {
    if (
      storeData.integration_type === IntegrationType.Shopify &&
      tab === STORES_DRAWER_TAB_VALUE.TaskSettings
    ) {
      setTab(STORES_DRAWER_TAB_VALUE.General);
    }
  }, [storeData, tab]);

  const handleCloseStoreDetail = useCallback(() => {
    navigate({
      pathname: location.pathname,
      search: omitFromQueryString(location.search, ['id', 'platform']),
    });
  }, [location]);

  useKeyDow({
    targetKey: 'Escape',
    disabled: !id,
    callback: handleCloseStoreDetail,
  });

  const { mutate: deleteConnectionMutation } = useDeleteConnectionMutation({
    onSuccess: () => {
      handleCloseStoreDetail();
    },
  });

  const handleChangeTab = useCallback((value: string) => {
    setTab(value);
  }, []);

  const renderTabComponent = useMemo(
    () => (
      <Tutorial
        isOpen={getTooltipOpen(TutorialData.OptimizeStore.id)}
        onClose={() =>
          updateUserTooltip({
            [TutorialData.OptimizeStore.id]: true,
          })
        }
        delay={300}
        {...TutorialData.OptimizeStore}
      >
        <DrawerTabs
          tabs={tabs}
          value={tab}
          onChange={handleChangeTab}
          lowerCaseLabel
          variant='scrollable'
        />
      </Tutorial>
    ),
    [tab, tabs, handleChangeTab, id, account],
  );

  const renderTabContent = useMemo(() => {
    switch (tab) {
      case STORES_DRAWER_TAB_VALUE.Teams:
        return <Teams data={storeData} />;
      case STORES_DRAWER_TAB_VALUE.DeliveryHours:
        return <DeliveryHours data={storeData} />;
      case STORES_DRAWER_TAB_VALUE.TaskSettings:
        return <TaskSettings data={storeData} />;
      case STORES_DRAWER_TAB_VALUE.DeliveryZoneFee:
        return <ZoneAndFree data={storeData} />;
      case STORES_DRAWER_TAB_VALUE.OrderLimit:
        return <OrderLimit data={storeData} />;
      case STORES_DRAWER_TAB_VALUE.General:
      default:
        return <General storeData={storeData} />;
    }
  }, [tab, storeData, isFetching]);

  return (
    <StyledIntegrationListContainer data-testid='store-detail'>
      <StyledCloseButton onClick={handleCloseStoreDetail} size='large'>
        <CloseIcon />
      </StyledCloseButton>

      <Stack direction='row' justifyContent='space-between' p={2} pb={1}>
        <Stack mr='auto' color={themes.color.violet900}>
          <Typography fontSize={18} fontWeight='bold' data-testid='store-name'>
            {storeData.display_name}
          </Typography>
          <LinkButton
            onClick={() => {
              showAlert({
                ...alertParams.warning,
                description:
                  'You are about to delete this store. This will affect \nexisting store integrations. Are you sure you want to continue?',
                okText: 'Yes, Delete',
                cancelText: 'Cancel',
                onOk: () => {
                  deleteConnectionMutation(id as string);
                },
              });
            }}
          >
            Delete
          </LinkButton>
        </Stack>
      </Stack>
      {renderTabComponent}

      <Box
        px={2}
        py={1}
        pb={0}
        mb={5.5}
        className='customized-scrollbar'
        sx={{
          overflow: 'auto',
          boxSizing: 'border-box',
          overflowX: 'hidden',
          height: '100%',
        }}
      >
        {isFetching && (
          <StyledLoading>
            <CircularProgress size={30} />
          </StyledLoading>
        )}
        {renderTabContent}
      </Box>
    </StyledIntegrationListContainer>
  );
};
