import {
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material';
import Button from '~/components/common/Button';
import { useLocation } from 'react-router-dom';
import {
  DateElement,
  IStore,
  OrderLimitType,
  StorePlatform,
  DeliveryHours as IDeliveryHours,
  DeliveryHourType,
} from '~/models/stores';
import themes from '~/themes';
import { fromQueryString } from '~/utils/queryString';
import { Field } from '~/components/common/Formik';
import { Formik } from 'formik';
import { DeliveryHoursSchema } from '~/utils/schema/stores';
import { Icon } from '~/components/common/Icon';
import { useRef, useState } from 'react';
import {
  cloneDeep,
  //  isEqual
} from 'lodash';
// import { alertParams, showAlert } from '~/components/common/Alert';
import { useUpdateConnectionMutation } from '~/services/api/userManagement';
import { DialogIndividualTimeSlots } from '../DeliveryHours/components/DialogIndividualTimeSlots';

type Props = {
  data: IStore;
  isFetching?: boolean;
};

export const OrderLimit = ({ data, isFetching }: Props) => {
  const location = useLocation();
  const search = fromQueryString(location.search);
  const platform = search.platform as StorePlatform;
  const orderLimitAllInputRef = useRef(null);
  const [openSetLimitDialog, setOpenSetLimitDialog] = useState(false);

  const { mutate: updateConnectionMutation, isLoading } =
    useUpdateConnectionMutation({});

  const hasSetLimitTimeSlots = (deliveryHoursData: IDeliveryHours) => {
    const deliveryHours = cloneDeep(deliveryHoursData);
    const weekDays = Object.keys(deliveryHours);
    let isCheck = false;
    weekDays.forEach((wd) => {
      const hours: DateElement = deliveryHours[wd];
      const timeSlots = [hours].map(({ time_slot }) => time_slot || []).flat();
      if ([hours].some((e) => e.limit) || timeSlots.some((e) => e.limit)) {
        isCheck = true;
      }
    });
    return isCheck;
  };

  const resetDeliveryHoursLimit = (deliveryHours: IDeliveryHours) => {
    const weekDays = Object.keys(deliveryHours);
    weekDays.forEach((wd) => {
      deliveryHours[wd].limit = null;
      deliveryHours[wd]?.time_slot?.forEach((ts) => {
        ts.limit = null;
      });
    });
    return deliveryHours;
  };

  //   const isEqualDeliveryHoursData = (oldData, newData) => {
  //     const oldDeliveryHours = cloneDeep(oldData);
  //     const newDeliveryHours = cloneDeep(newData);

  //     return isEqual(
  //       {
  //         delivery_window: oldDeliveryHours.delivery_window,
  //         next_available_time: oldDeliveryHours.next_available_time,
  //       },
  //       {
  //         delivery_window: newDeliveryHours.delivery_window,
  //         next_available_time: newDeliveryHours.next_available_time,
  //       },
  //     );
  //   };

  const onSubmit = (values) => {
    // const isShowWarningMsg =
    //   values.enable_delivery &&
    //   (values.limit_all_slot || hasSetLimitTimeSlots(values.delivery_hours)) &&
    //   !isEqualDeliveryHoursData(data.delivery, values);

    if (values.limit_type === OrderLimitType.LimitAllSlot) {
      values.delivery_hours = resetDeliveryHoursLimit(values.delivery_hours);
    } else {
      values.limit_all_slot = null;
    }

    const params = {
      id: data.id,
      enable_delivery: values.enable_delivery,
      ...(values.enable_delivery && {
        delivery: values,
      }),
      ...(values?.limit_type && {
        limit_type: values.limit_type,
      }),
      limit_all_slot: values.limit_all_slot
        ? parseInt(values.limit_all_slot, 10)
        : null,
      delivery_hour_type: values.delivery_hour_type,
    };

    // if (isShowWarningMsg) {
    //   showAlert({
    //     ...alertParams.warning,
    //     description:
    //       'Updating time slot length will reset your delivery schedule configurations.\n Are you sure you want to continue?',
    //     okText: 'Continue',
    //     cancelText: 'Cancel',
    //     onOk: () => updateConnectionMutation(params),
    //   });
    // } else {
    //   updateConnectionMutation(params);
    // }
    updateConnectionMutation(params);
  };

  if (platform === StorePlatform.Breadstack) {
    return (
      <Stack spacing={2}>
        <Typography
          sx={{
            borderRadius: '5px',
            background: themes.bg.lightPurple,
            padding: 1,
          }}
        >
          Set the limit to number of order that can be place within a delivery
          time slot.
        </Typography>
        <Button
          noRounder
          buttonType='default'
          sx={{ width: 'fit-content' }}
          onClick={() => window.open(data.breadstack_url, '_blank')}
        >
          Manage in Breadstack
        </Button>
      </Stack>
    );
  }

  return (
    <Formik
      initialValues={{
        delivery_window: 15,
        next_available_time: 30,
        ...data.delivery,
        enable_delivery: data.enable_delivery || false,
        limit_all_slot: data?.limit_all_slot || null,
        delivery_hour_type:
          data?.delivery?.delivery_hour_type || DeliveryHourType.Different,
        delivery_hours: data.delivery?.delivery_hours,
        special_hours: data.delivery?.special_hours || [],
        limit_type: data?.limit_type || OrderLimitType.LimitAllSlot,
      }}
      validationSchema={DeliveryHoursSchema}
      enableReinitialize
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, isValid, dirty, submitForm }) => (
        <>
          <Typography mt={0.8}>
            Limit the number of orders customers can place during each time
            slot.
          </Typography>
          <RadioGroup
            sx={{
              display: 'flex',
              flexDirection: 'column',
              label: {
                width: 'fit-content',
              },
            }}
            onChange={(e) => {
              setFieldValue('limit_type', e.target.value);
              if (e.target.value === OrderLimitType.LimitAllSlot) {
                setTimeout(() => orderLimitAllInputRef.current.focus(), 0);
              }
            }}
            value={values.limit_type}
          >
            <FormControlLabel
              value={OrderLimitType.LimitAllSlot}
              control={<Radio />}
              label='Set for all time slots'
            />
            {values.limit_type === OrderLimitType.LimitAllSlot && (
              <Field.TextInput
                inputRef={orderLimitAllInputRef}
                type='number'
                name='limit_all_slot'
                inputProps={{
                  min: 1,
                  step: 'any',
                }}
                sx={{ width: '50%' }}
              />
            )}
            <FormControlLabel
              value={OrderLimitType.LimitIndividualSlot}
              control={<Radio />}
              label='Set for individual time slots'
            />
            {values.limit_type === OrderLimitType.LimitIndividualSlot && (
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                p={1.2}
                sx={{
                  mt: 1,
                  background: themes.bg.lightPurple,
                  borderRadius: '5px',
                }}
              >
                <Typography>
                  {hasSetLimitTimeSlots(data.delivery.delivery_hours)
                    ? 'Order limit has been set for individual time slots'
                    : 'Set order limit for individual time slots'}
                </Typography>
                {hasSetLimitTimeSlots(data.delivery.delivery_hours) ? (
                  <IconButton onClick={() => setOpenSetLimitDialog(true)}>
                    <Icon name='ionic-ios-settings' useBackgroundImg />
                  </IconButton>
                ) : (
                  <Button
                    buttonType='default'
                    noRounder
                    onClick={() => setOpenSetLimitDialog(true)}
                  >
                    Set Limit
                  </Button>
                )}
              </Stack>
            )}
          </RadioGroup>
          <Button
            disabled={!isValid || !dirty}
            className='save-change-btn'
            fullWidth
            loading={isLoading || isFetching}
            onClick={submitForm}
          >
            Save Changes
          </Button>
          {openSetLimitDialog && (
            <DialogIndividualTimeSlots
              open={openSetLimitDialog}
              stores={{ ...data, ...values }}
              onClose={() => setOpenSetLimitDialog(false)}
              onSaveDataSetLimit={(val) => {
                setFieldValue('delivery_hours', val);
              }}
            />
          )}
        </>
      )}
    </Formik>
  );
};
