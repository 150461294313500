import { Box, Stack, Typography } from '@mui/material';
import { styled } from '~/themes';

type TrackVerticalProgressProps = {
  value?: number;
};

export const StyledLine = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: 2,
  backgroundColor: theme.bg.gray600,
  top: 15,
  left: 5,
  zIndex: -1,
}));

export const StyledLineProgress = styled(Box)(({ theme }) => ({
  width: 2,
  backgroundColor: theme.bg.darkPurple,
}));

export const StyledTrackItem = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'checked',
})<{ checked: boolean }>(({ checked, theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: 18,
  ...(checked && {
    '& > div': {
      backgroundColor: `${theme.bg.darkPurple} !important`,
      border: 'none',
    },
  }),
}));

export const StyledTrackIcon = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  width: theme.spacing(1.2),
  height: theme.spacing(1.2),
  marginRight: theme.spacing(1.5),
  backgroundColor: theme.bg.white,
  border: `2.5px solid ${theme.bg.gray600}`,
}));
export const StyledTrackTitle = styled(Typography)(({ theme }) => ({
  color: theme.bg.darkPurple,
  fontWeight: 500,
  fontSize: 16,
}));

export const TrackVerticalProgress: React.FC<TrackVerticalProgressProps> = ({
  value,
}) => {
  const progressList = [
    {
      label: 'Order confirmed',
      value: 0.2,
    },
    {
      label: 'Preparing your order',
      value: 0.4,
    },
    {
      label: 'Picking up your order',
      value: 0.6,
    },
    {
      label: 'Out for delivery',
      value: 0.8,
    },
    {
      label: 'Delivered',
      value: 1,
    },
  ];

  const calcLineProgressHeight = (val: number) => {
    const lineHeight = progressList.filter((e) => e.value < value).length * 48;
    if (val === 0.9) return lineHeight - 30;
    return lineHeight;
  };

  return (
    <Stack
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        gap: '30px',
      }}
    >
      <StyledLine sx={{ height: (progressList.length + 1) * 30 }}>
        <StyledLineProgress height={calcLineProgressHeight(value)} />
      </StyledLine>
      {progressList.map((item) => (
        <StyledTrackItem checked={item.value <= value} key={item.value}>
          <StyledTrackIcon />
          <StyledTrackTitle>{item.label}</StyledTrackTitle>
        </StyledTrackItem>
      ))}
    </Stack>
  );
};
